import { Injectable } from '@angular/core';
import { LoginI } from '../modelos/login.interface';
import { ResponseI } from '../modelos/response.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatosAlumnos} from '../modelos/DatosAlumnos';
import { DatosProductos } from '../modelos/DatosProductos';
import { DatosBancos } from '../modelos/DatosBancos';
import { PagosComponent } from '../dashboard/pagos/pagos.component';
import { DatosDetalleVP } from '../modelos/DatosDetalleVP';
import { DatosDepositoBan } from '../modelos/DatosDepositoBan';
import { DatosPagos } from '../modelos/DatosPagos';
import { AddAlumnos } from '../dashboard/Addmodelos/AddAlumnos';
import { AddProductos } from '../dashboard/Addmodelos/AddProductos';
import { AddPagos } from '../dashboard/Addmodelos/AddPagos';
import { AddBanco } from '../dashboard/Addmodelos/AddBanco';
import { AddDepositoBanco } from '../dashboard/Addmodelos/AddDepositoBanco';
import { AddVentas } from '../dashboard/Addmodelos/AddVentas';
import { BusquedaId } from '../dashboard/Addmodelos/BusquedaId';
import { DatosEditVp } from '../modelos/DatosVPEdit';
import { DatosAlumnosEdit } from '../dashboard/EditModelos/DatosAlumnosEdit';
import { DatosArea } from '../modelos/DatosArea';
import { DatosGrupos } from '../modelos/DatosGrupo';
import { DatosCompania } from '../modelos/DatosCompania';
import { DatosEstadoC } from '../modelos/DatosEstadoC';
import { DatosBarrio } from '../modelos/DatosBarrio';
import { DatosColonia } from '../modelos/DatosColonia';
import { DatosAldea } from '../modelos/DatosAldea';
import { DatosMunicipio } from '../modelos/DatosMunicipio';
import { AddFicha } from '../dashboard/Addmodelos/AddFichaT';
import { DatosLugarEstudio } from '../modelos/DatosLugarEstudio';
import { DatosGradoCursa } from '../modelos/DatosGradoCursa';
import { DatosOrigenB } from '../modelos/DatosOrigenB';
import { DatosParentesco } from '../modelos/DatosParentesco';
import { DatosNombreGrupo } from '../modelos/DatosNombreGrupo';
import { DatosCategoriaPago } from '../modelos/DatosCategoriaPago';
import { DatosSector } from '../modelos/DatosSector';
import { DatosAnio } from '../modelos/DatosAnio';
import { DatosInstructor } from '../modelos/DatosInstructor';
import { DatosPlan } from '../modelos/DatosPlan';
import { DatosSalon } from '../modelos/DatosSalon';
import { DatosNombreGrupoM } from '../modelos/DatosNombreGrupoM';
import { AddGrupos } from '../dashboard/Addmodelos/AddGrupos';
import { AddCategoria } from '../dashboard/Addmodelos/AddCategoria';
import { AddListadosMantenimiento } from '../dashboard/Addmodelos/AddListadosMantenimiento';
import { AddInstructor } from '../dashboard/Addmodelos/AddInstructor';
import { AddDescuentos } from '../dashboard/Addmodelos/AddDescuentos';
import { DatosDescuentos } from '../modelos/DatosDescuentos';
import { DatosNotas, DatosNotasTablas, DatosReporteA } from '../modelos/DatosNotas';
import { DatosRecibo } from '../modelos/DatosRecibos';
import { DatosDash1 } from '../modelos/DatosDash1';
import { environment } from 'src/environments/environment';
import { AddUsuario } from '../dashboard/Addmodelos/AddUsuario';
import { DatosBitacoras } from '../modelos/DatosBitacoras';
import { DatosAlumnosMora } from '../modelos/DatosAlumnosMora';


@Injectable({
  providedIn: 'root'
})
export class ServiceGService {

// SERVIDOR DE DESARROLLO

url: string = "https://api.centroprofesionaldecomputocpc.com/api/";
// url: string = "http://localhost:1211/api/";


url2 : string = "api.centroprofesionaldecomputocpc.com";

urlLocal: string = "http://localhost:1211/api/";

urlLocal2: string = "http://localhost:2211/api/";

urFija: string = "https://api.centroprofesionaldecomputocpc.com/api/";

private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

LoginIn(form : LoginI):Observable<any>{

  // let direccion = this.url + "login/authenticate"
  // //  let direccion = environment.apiUrl + 'login/authenticate';
  // return this.http.post(direccion,form);

  // console.log(this.apiUrl)

  let direccion = this.urFija + 'login/authenticate';
  return this.http.post(direccion, form);

}

//  GETIMPRESION

getImpresion(): Observable<any>{

return this.http.get<any>('api.centroprofesionaldecomputocpc.com//api/getNormal')

}

//GET RECIBOS

GetLimitesRecibos (): Observable<any> {

  let direccion = this.url + "GetLimitesRecibos"
  return this.http.get<any>(direccion);

}

//add recibo

addLimiteRecibo(form : DatosRecibo):Observable<any>{

  let direccion = this.url + "addLimiteRecibo"
  return this.http.post(direccion,form);

}

//GET ALUMNOS //


getAlumnos (): Observable<DatosAlumnos[]> {

  let direccion = this.url + "getalumnos"
  return this.http.get<DatosAlumnos[]>(direccion);

}


DatosDash1 (): Observable<DatosDash1[]> {

  let direccion = this.url + "GetDatosDash1"
  return this.http.get<DatosDash1[]>(direccion);

}


//GET Preinscritos //

getPreinscritos (): Observable<DatosAlumnos[]> {

  let direccion = this.url + "getPreinscritos"
  return this.http.get<DatosAlumnos[]>(direccion);

}


getProductos (): Observable<DatosProductos[]> {

  let direccion = this.url + "getProductoList"
  return this.http.get<DatosProductos[]>(direccion);

}

//LISTADOS GENERALES


getAnio (): Observable<DatosAnio[]> {

  let direccion = this.url + "getAnio"
  return this.http.get<DatosAnio[]>(direccion);

}

getinstructor (): Observable<DatosInstructor[]> {

  let direccion = this.url + "getinstructor"
  return this.http.get<DatosInstructor[]>(direccion);

}

getPlan (): Observable<DatosPlan[]> {

  let direccion = this.url + "getPlan"
  return this.http.get<DatosPlan[]>(direccion);

}


getSalon (): Observable<DatosSalon[]> {

  let direccion = this.url + "getSalon"
  return this.http.get<DatosSalon[]>(direccion);

}

getNombreGrupoM (): Observable<DatosNombreGrupoM[]> {

  let direccion = this.url + "getNombreGrupoM"
  return this.http.get<DatosNombreGrupoM[]>(direccion);

}


getArea (): Observable<DatosArea[]> {

  let direccion = this.url + "getArea"
  return this.http.get<DatosArea[]>(direccion);

}


getGrupo (): Observable<DatosGrupos[]> {

  let direccion = this.url + "getGrupo"
  return this.http.get<DatosGrupos[]>(direccion);

}

getCompania (): Observable<DatosCompania[]> {

  let direccion = this.url + "getCompania"
  return this.http.get<DatosCompania[]>(direccion);

}

getEstadoC (): Observable<DatosEstadoC[]> {

  let direccion = this.url + "getEstadoC"
  return this.http.get<DatosEstadoC[]>(direccion);

}

getBarrio (): Observable<DatosBarrio[]> {

  let direccion = this.url + "getBarrio"
  return this.http.get<DatosBarrio[]>(direccion);

}

getColonia (): Observable<DatosColonia[]> {

  let direccion = this.url + "getColonia"
  return this.http.get<DatosColonia[]>(direccion);

}

getAldea (): Observable<DatosAldea[]> {

  let direccion = this.url + "getAldea"
  return this.http.get<DatosAldea[]>(direccion);

}

getMunicipio (): Observable<DatosMunicipio[]> {

  let direccion = this.url + "getMunicipio"
  return this.http.get<DatosMunicipio[]>(direccion);

}


getLugarEstudio (): Observable<DatosLugarEstudio[]> {

  let direccion = this.url + "getLugarEstudio"
  return this.http.get<DatosLugarEstudio[]>(direccion);

}

getGradoCursa (): Observable<DatosGradoCursa[]> {

  let direccion = this.url + "getGradoCursa"
  return this.http.get<DatosGradoCursa[]>(direccion);

}


getOrigenBecado (): Observable<DatosOrigenB[]> {

  let direccion = this.url + "getOrigenBecado"
  return this.http.get<DatosOrigenB[]>(direccion);

}


getParentesco (): Observable<DatosParentesco[]> {

  let direccion = this.url + "getParentesco"
  return this.http.get<DatosParentesco[]>(direccion);

}

getCategoriaPago (): Observable<DatosCategoriaPago[]> {

  let direccion = this.url + "getCategoriaPago"
  return this.http.get<DatosCategoriaPago[]>(direccion);

}

getSetor (): Observable<DatosSector[]> {

  let direccion = this.url + "getSetor"
  return this.http.get<DatosSector[]>(direccion);

}

getProductosList (): Observable<DatosProductos[]> {

  let direccion = this.url + "getProductoList"
  return this.http.get<DatosProductos[]>(direccion);

}


getDescuentos (): Observable<DatosDescuentos[]> {

  let direccion = this.url + "getDescuentos"
  return this.http.get<DatosDescuentos[]>(direccion);

}


getNombreGrupo (idNombreGrupoo: string): Observable<DatosNombreGrupo[]> {

  let direccion = this.url + "getNombreGrupo?id="+idNombreGrupoo
  return this.http.get<DatosNombreGrupo[]>(direccion);

}

GetUsuario (idUsuario: string | null ): Observable<LoginI[]> {

  let direccion = this.url + "GetUsuario?id="+idUsuario
  return this.http.get<LoginI[]>(direccion);

}











getBancos (): Observable<DatosBancos[]> {

  let direccion = this.url + "getBancos"
  return this.http.get<DatosBancos[]>(direccion);

}


getDeposito (): Observable<DatosDepositoBan[]> {

  let direccion = this.url + "getdepositoBanco"
  return this.http.get<DatosDepositoBan[]>(direccion);

}

getPagos (): Observable<DatosPagos[]> {

  let direccion = this.url + "getPagos"
  return this.http.get<DatosPagos[]>(direccion);

}


getDetalleVP (): Observable<DatosDetalleVP[]> {

  let direccion = this.url + "getDetalleVP"
  return this.http.get<DatosDetalleVP[]>(direccion);

}

//GET'S CON SOLICITUD DE EDICION

getAlumnosEdit (): Observable<DatosAlumnos[]> {

  let direccion = this.url + "getAlumnosEdit"
  return this.http.get<DatosAlumnos[]>(direccion);

}


getProductosEdit (): Observable<DatosProductos[]> {

  let direccion = this.url + "getProductoEdit"
  return this.http.get<DatosProductos[]>(direccion);

}

getBancosEdit (): Observable<DatosBancos[]> {

  let direccion = this.url + "getBancosEdit"
  return this.http.get<DatosBancos[]>(direccion);

}


getDepositoEdit (): Observable<DatosDepositoBan[]> {

  let direccion = this.url + "getdepositoBancoEdit"
  return this.http.get<DatosDepositoBan[]>(direccion);

}

getPagosEdit (): Observable<DatosPagos[]> {

  let direccion = this.url + "getPagosEdit"
  return this.http.get<DatosPagos[]>(direccion);

}


getDetalleVPEdit (): Observable<DatosDetalleVP[]> {

  let direccion = this.url + "GetDetalleVPEdit"
  return this.http.get<DatosDetalleVP[]>(direccion);

}


getdescuentoEdit (): Observable<DatosDescuentos[]> {

  let direccion = this.url + "getdescuentoEdit"
  return this.http.get<DatosDescuentos[]>(direccion);

}

//GET'S CON AUTORIZACION DE EDICION

getAlumnosEdit2 (): Observable<DatosAlumnos[]> {

  let direccion = this.url + "getAlumnosEdit2"
  return this.http.get<DatosAlumnos[]>(direccion);

}

getProductoEdit2 (): Observable<DatosProductos[]> {

  let direccion = this.url + "getProductoEdit2"
  return this.http.get<DatosProductos[]>(direccion);

}

getBancosEdit2 (): Observable<DatosBancos[]> {

  let direccion = this.url + "getBancosEdit2"
  return this.http.get<DatosBancos[]>(direccion);

}

getPagosEdit2 (): Observable<DatosPagos[]> {

  let direccion = this.url + "getPagosEdit2"
  return this.http.get<DatosPagos[]>(direccion);

}

getdepositoBancoEdit2 (): Observable<DatosDepositoBan[]> {

  let direccion = this.url + "getdepositoBancoEdit2"
  return this.http.get<DatosDepositoBan[]>(direccion);

}

getDetalleVPAut (): Observable<DatosDetalleVP[]> {

  let direccion = this.url + "GetDetalleVPAut"
  return this.http.get<DatosDetalleVP[]>(direccion);

}

getdescuentoEdit2 (): Observable<DatosDescuentos[]> {

  let direccion = this.url + "getdescuentoEdit2"
  return this.http.get<DatosDescuentos[]>(direccion);

}
//GET PARA EDITAR O BUSQUEDAS ESPECIFICAS


getAlumnosEditForm (id: string): Observable<DatosAlumnosEdit[]> {

  let direccion = this.url + "GetAlumnosEditForm?id="+id
  return this.http.get<DatosAlumnosEdit[]>(direccion);

}

getProductoEditForm (id: string): Observable<AddProductos[]> {

  let direccion = this.url + "getProductoEditForm?id="+id
  return this.http.get<AddProductos[]>(direccion);

}

getBancosEditForm (id: string): Observable<AddBanco[]> {

  let direccion = this.url + "getBancosEditForm?id="+id
  return this.http.get<AddBanco[]>(direccion);

}

getPagosEditForm (id: string): Observable<AddPagos[]> {

  let direccion = this.url + "getPagosEditForm?id="+id
  return this.http.get<AddPagos[]>(direccion);

}

getdepositoBancoEditForm (id: string): Observable<DatosDepositoBan[]> {

  let direccion = this.url + "getdepositoBancoEditForm?id="+id
  return this.http.get<DatosDepositoBan[]>(direccion);

}

getVentasEditForm (id: string): Observable<DatosDetalleVP[]> {

  let direccion = this.url + "GetDetalleVPEditFrom?id="+id
  return this.http.get<DatosDetalleVP[]>(direccion);

}

getdescuentoEditForm (id: string): Observable<DatosDescuentos[]> {

  let direccion = this.url + "getdescuentoEditForm?id="+id
  return this.http.get<DatosDescuentos[]>(direccion);

}



GetGruposId (id: string): Observable<AddGrupos[]> {

  let direccion = this.url + "GetGruposId?id="+id
  return this.http.get<AddGrupos[]>(direccion);

}

getCategoriaId (id: number): Observable<AddCategoria[]> {

  let direccion = this.url + "getCategoriaId?id="+id
  return this.http.get<AddCategoria[]>(direccion);

}

getOrigenBEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getOrigenBEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getAldeaEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getAldeaEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}


getBarrioEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getBarrioEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}


getColoniaEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getColoniaEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}


getMunicipioEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getMunicipioEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getLestudioEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getLestudioEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getGradoEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getGradoEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getParentescoEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getParentescoEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getRercordEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getRercordEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getInstructorEdit (id: number): Observable<AddInstructor[]> {

  let direccion = this.url + "getInstructorEdit?id="+id
  return this.http.get<AddInstructor[]>(direccion);

}


getNombregEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getNombregEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getEstadoCivilEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getEstadoCivilEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

getCursosPanioEdit (id: number): Observable<AddListadosMantenimiento[]> {

  let direccion = this.url + "getCursosPanioEdit?id="+id
  return this.http.get<AddListadosMantenimiento[]>(direccion);

}

// GET FICHA TECNICA


getAlumnosFichaTecnica (idAlumno: string): Observable<AddFicha[]> {

  let direccion = this.url + "getAlumnosFichaTecnica?idAlumno="+idAlumno
  return this.http.get<AddFicha[]>(direccion);

}

getAlumnosFichaTecnicaPreInscritos (idAlumno: string): Observable<AddFicha[]> {

  let direccion = this.url + "getAlumnosFichaTecnicaPreInscritos?idAlumno="+idAlumno
  return this.http.get<AddFicha[]>(direccion);

}

getAlumnosFichaTecnicaHistorico (idAlumno: string): Observable<AddFicha[]> {

  let direccion = this.url + "getAlumnosFichaTecnicaHistorico?idAlumno="+idAlumno
  return this.http.get<AddFicha[]>(direccion);

}


//ADD MANTENIMIENTOS

addGrupo(form : AddGrupos):Observable<any>{

  let direccion = this.url + "addGrupo"
  return this.http.post(direccion,form);

}

addCategoria(form : AddCategoria):Observable<any>{

  let direccion = this.url + "addCategoria"
  return this.http.post(direccion,form);

}

addOringen(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addOringen"
  return this.http.post(direccion,form);

}

addAldea(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addAldea"
  return this.http.post(direccion,form);

}

addBarrio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addBarrio"
  return this.http.post(direccion,form);

}

addColonia(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addColonia"
  return this.http.post(direccion,form);

}

addMunicipio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addMunicipio"
  return this.http.post(direccion,form);

}

addLestudio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addLestudio"
  return this.http.post(direccion,form);

}

addGrados(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addGrados"
  return this.http.post(direccion,form);

}

addParentesco(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addParentesco"
  return this.http.post(direccion,form);

}

addConcepto(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addConcepto"
  return this.http.post(direccion,form);

}

addInstructor(form : AddInstructor):Observable<any>{

  let direccion = this.url + "addInstructor"
  return this.http.post(direccion,form);

}

addNombreGrupo(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "addNombreGrupo"
  return this.http.post(direccion,form);

}

AddEstadoCivil(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "AddEstadoCivil"
  return this.http.post(direccion,form);

}

Adddescuento(form : AddDescuentos):Observable<any>{

  let direccion = this.url + "Adddescuento"
  return this.http.post(direccion,form);

}


//APIS EDIT MANTENIMIENTO

editGrupos(form : AddGrupos):Observable<any>{

  let direccion = this.url + "editGrupos"
  return this.http.post(direccion,form);

}

editCategoria(form : AddCategoria):Observable<any>{

  let direccion = this.url + "editCategoria"
  return this.http.post(direccion,form);

}

editOrigenB(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editOrigenB"
  return this.http.post(direccion,form);

}


editAldea(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editAldea"
  return this.http.post(direccion,form);

}

editBarrio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editBarrio"
  return this.http.post(direccion,form);

}

editColonia(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editColonia"
  return this.http.post(direccion,form);

}

editMunicipio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editMunicipio"
  return this.http.post(direccion,form);

}

editLestudio(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editLestudio"
  return this.http.post(direccion,form);

}
editGrado(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editGrado"
  return this.http.post(direccion,form);

}

editParentesco(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editParentesco"
  return this.http.post(direccion,form);

}

editRecord(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editRecord"
  return this.http.post(direccion,form);

}

editInstructor(form : AddInstructor):Observable<any>{

  let direccion = this.url + "editInstructor"
  return this.http.post(direccion,form);

}

editNombreG(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editNombreG"
  return this.http.post(direccion,form);

}

editEstadoC(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editEstadoC"
  return this.http.post(direccion,form);

}

editCursos(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "editCursos"
  return this.http.post(direccion,form);

}


editUsuarioContrasenia(form : AddUsuario):Observable<any>{

  let direccion = this.url + "editUsuarioContrasenia"
  return this.http.post(direccion,form);

}




AddAlumno(form : AddAlumnos):Observable<any>{

  let direccion = this.url + "addAlumno"
  return this.http.post(direccion,form);

}




AddFichaT(form : AddFicha):Observable<any>{

  let direccion = this.url + "addFichaT"
  return this.http.post(direccion,form);

}

addFichaTPresincritosV2(form : AddFicha):Observable<any>{

  let direccion = this.url + "addFichaTPresincritosV2"
  return this.http.post(direccion,form);

}

addFichaTecnicaV2(form : AddFicha):Observable<any>{

  let direccion = this.url + "addFichaTecnicaV2"
  return this.http.post(direccion,form);

}

addFichaPre(form : AddFicha):Observable<any>{

  let direccion = this.url + "addFichaPre"
  return this.http.post(direccion,form);

}

AddProductos(form : AddProductos):Observable<any>{

  let direccion = this.url + "addProductos"
  return this.http.post(direccion,form);

}

AddBancos(form : AddBanco):Observable<any>{

  let direccion = this.url + "addbanco"
  return this.http.post(direccion,form);

}

AddPagos(form : AddPagos):Observable<any>{

  let direccion = this.url + "addPagos"
  return this.http.post(direccion,form);

}

Adddepositos(form : AddDepositoBanco):Observable<any>{

  let direccion = this.url + "addDepban"
  return this.http.post(direccion,form);

}


AddVenta(form : AddVentas):Observable<any>{

  let direccion = this.url + "addVenta"
  return this.http.post(direccion,form);

}

Add2Venta(form : AddVentas):Observable<any>{

  let direccion = this.url + "addVenta2"
  return this.http.post(direccion,form);

}

addVentaEspecial(form : AddVentas):Observable<any>{

  let direccion = this.url + "addVentaEspecial"
  return this.http.post(direccion,form);

}



AddVenta2(form : AddVentas):Observable<any>{

  let direccion = this.url + "addVentaPago"
  return this.http.post(direccion,form);

}

//EDIT

editFichaT(form : AddFicha):Observable<any>{

  let direccion = this.url + "editFichaT"
  return this.http.post(direccion,form);

}

EditarAlumno(form : AddAlumnos):Observable<any>{

  let direccion = this.url + "editAlumno"
  return this.http.post(direccion,form);

}

busquedaAlumno(form : AddAlumnos):Observable<any>{

  let direccion = this.url + "busquedaAlumno"
  return this.http.post(direccion,form);

}

Productosedit(form : AddProductos):Observable<any>{

  let direccion = this.url + "Productosedit"
  return this.http.post(direccion,form);

}

bancoedit(form : AddBanco):Observable<any>{

  let direccion = this.url + "bancoedit"
  return this.http.post(direccion,form);

}

Pagosedit(form : AddPagos):Observable<any>{

  let direccion = this.url + "Pagosedit"
  return this.http.post(direccion,form);

}

Depbanedit(form : AddDepositoBanco):Observable<any>{

  let direccion = this.url + "Depbanedit"
  return this.http.post(direccion,form);

}

editVentaProducto(form : AddVentas):Observable<any>{

  let direccion = this.url + "editVentaProducto"
  return this.http.post(direccion,form);

}

editVentaPago(form : AddVentas):Observable<any>{

  let direccion = this.url + "editVentaPago"
  return this.http.post(direccion,form);

}


Descuentosedit(form : AddDescuentos):Observable<any>{

  let direccion = this.url + "Descuentosedit"
  return this.http.post(direccion,form);

}

//APIS DE BUSQUEDA POR ID

GetEstadoPagos(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostPagos"
  return this.http.post(direccion,form);

}

GetEstadoPagosEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostPagosEspecial"
  return this.http.post(direccion,form);

}



PostDetallePagosVarios(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostDetallePagosVarios"
  return this.http.post(direccion,form);

}


PostDetallePagosVariosEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostDetallePagosVariosEspecial"
  return this.http.post(direccion,form);

}


PostDetallePagos(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostDetallePagos"
  return this.http.post(direccion,form);

}

PostDetallePagosEspeciales(form : BusquedaId):Observable<any>{

  let direccion = this.url + "PostDetallePagosEspeciales"
  return this.http.post(direccion,form);

}



pagoInscripcion(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcion"
  return this.http.post(direccion,form);

}

pagoInscripcion2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcion2"
  return this.http.post(direccion,form);

}

pagoInscripcionEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcionEspecial"
  return this.http.post(direccion,form);

}

pagoInscripcionEspecial2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcionEspecial2"
  return this.http.post(direccion,form);

}

pagoInscripcionEnlinea(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcionEnlinea"
  return this.http.post(direccion,form);

}

pagoInscripcionEnlinea2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoInscripcionEnlinea2"
  return this.http.post(direccion,form);

}


pagoManual(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManual"
  return this.http.post(direccion,form);

}

pagoManual2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManual2"
  return this.http.post(direccion,form);

}
pagoManualEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEspecial"
  return this.http.post(direccion,form);

}

pagoManualEspecial2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEspecial2"
  return this.http.post(direccion,form);

}

pagoManualEnlinea(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEnlinea"
  return this.http.post(direccion,form);

}


pagoManualEnlinea2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEnlinea2"
  return this.http.post(direccion,form);

}



pagoManualS(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualS"
  return this.http.post(direccion,form);

}

pagoManualS2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualS2"
  return this.http.post(direccion,form);

}

pagoManualEspecialS(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEspecialS"
  return this.http.post(direccion,form);

}

pagoManualEspecialS2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEspecialS2"
  return this.http.post(direccion,form);

}

pagoManualEnlineaS(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEnlineaS"
  return this.http.post(direccion,form);

}


pagoManualEnlineaS2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoManualEnlineaS2"
  return this.http.post(direccion,form);

}






pagoFinAnio(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnio"
  return this.http.post(direccion,form);

}

pagoFinAnio2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnio2"
  return this.http.post(direccion,form);

}

pagoFinAnioEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnioEspecial"
  return this.http.post(direccion,form);

}

pagoFinAnioEspecial2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnioEspecial2"
  return this.http.post(direccion,form);

}

pagoFinAnioEnlinea(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnioEnlinea"
  return this.http.post(direccion,form);

}

pagoFinAnioEnlinea2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoFinAnioEnlinea2"
  return this.http.post(direccion,form);

}



pagoMensualidad(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidad"
  return this.http.post(direccion,form);

}

pagoMensualidad2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidad2"
  return this.http.post(direccion,form);

}

pagoMensualidadEspecial(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidadEspecial"
  return this.http.post(direccion,form);

}

pagoMensualidadEspecial2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidadEspecial2"
  return this.http.post(direccion,form);

}


pagoMensualidadEnLinea(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidadEnLinea"
  return this.http.post(direccion,form);

}

pagoMensualidadEnlinea2(form : BusquedaId):Observable<any>{

  let direccion = this.url + "pagoMensualidadEnlinea2"
  return this.http.post(direccion,form);

}




GetAlumnoId(form : BusquedaId):Observable<any>{

  let direccion = this.url + "getAlumnosID"
  return this.http.post(direccion,form);

}


//APIS SOLICITUD DE EDITAR 1
EditAlumno1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoAlumno"
  return this.http.post(direccion,form);

}

EditProducto1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoProductos"
  return this.http.post(direccion,form);

}

EditBanco1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadobanco"
  return this.http.post(direccion,form);

}

EditPagos1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoPagos"
  return this.http.post(direccion,form);

}

EditDeposito1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoDepban"
  return this.http.post(direccion,form);

}

EditDescuento1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoDeposito"
  return this.http.post(direccion,form);

}

EditVenta1(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoVentaProducto"
  return this.http.post(direccion,form);

}


//APIS AUTORIZACION DE EDITAR 2

EditAlumno2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoAlumno2"
  return this.http.post(direccion,form);

}

EditProducto2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoProductos2"
  return this.http.post(direccion,form);

}

EditBanco2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadobanco2"
  return this.http.post(direccion,form);

}

EditPagos2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoPagos2"
  return this.http.post(direccion,form);

}

EditDeposito2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoDepban2"
  return this.http.post(direccion,form);

}

EditDescuento2(form : DatosDescuentos):Observable<any>{

  let direccion = this.url + "estadoDescuento2"
  return this.http.post(direccion,form);

}

EditVenta2(form : DatosEditVp):Observable<any>{

  let direccion = this.url + "estadoVentaProducto2"
  return this.http.post(direccion,form);

}


// funcion de impresion


estadoVentaImpresion(form : AddListadosMantenimiento):Observable<any>{

  let direccion = this.url + "estadoVentaImpresion"
  return this.http.post(direccion,form);

}







//SERVICIOS DE MODULO DE NOTAS


getGruposNotas(form : DatosNotas):Observable<any>{

  let direccion = this.url + "getGruposNotas"
  return this.http.post(direccion,form);

}


getCursosNotas(form : DatosNotas):Observable<any>{

  let direccion = this.url + "getCursosNotas"
  return this.http.post(direccion,form);

}


getCursosTabla(form : DatosNotasTablas):Observable<any>{

  let direccion = this.url + "getCursosTabla"
  return this.http.post(direccion,form);

}

getCursosNotaEspecifica(form : DatosNotasTablas):Observable<any>{

  let direccion = this.url + "getCursosNotaEspecifica"
  return this.http.post(direccion,form);

}

addNotasAlumno(form : DatosNotasTablas):Observable<any>{

  let direccion = this.url + "addNotasAlumno"
  return this.http.post(direccion,form);

}

addReporte(form : DatosReporteA):Observable<any>{

  let direccion = this.url + "addReporte"
  return this.http.post(direccion,form);

}

habilitarEdicionNotas():Observable<any>{

  let direccion = this.url + "habilitarEdicionNotas"
  return this.http.post(direccion,null);

}


//BITACORAS


getBitacoraObservacionAlumno(form : DatosBitacoras):Observable<any>{

  let direccion = this.url + "getBitacoraObservacionAlumno"
  return this.http.post(direccion,form);

}


//API GET TABLAS GENERALES


GetAll(form : BusquedaId):Observable<any>{

  let direccion = this.url + "GetAll"
  return this.http.post(direccion,form);

}

AddFichaTPre(form : AddFicha):Observable<any>{

  let direccion = this.url + "addFichaTPre"
  return this.http.post(direccion,form);

}


//MODULO DE RECORD CREDITICIO

getAlumnosMora():Observable<any>{

  let direccion = this.url + "getAlumnosMora"
  return this.http.get(direccion);

}

addAbonoMora(form : DatosAlumnosMora):Observable<any>{

  let direccion = this.url + "addAbonoMora"
  return this.http.post(direccion,form);

}


//MODULO DE CIERRE DE ALUMNOS Y METODO HISTORICO

getAlumnosHistorico():Observable<any>{

  let direccion = this.url + "getAlumnosHistorico"
  return this.http.get(direccion);

}

cierrePagos():Observable<any>{

  let direccion = this.url + "cierrePagos"
  return this.http.post(direccion, null);

}

CierreCiclo():Observable<any>{

  let direccion = this.url + "CierreCiclo"
  return this.http.post(direccion, null);

}


}
