import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { VigilanteGuard } from './vigilante.guard';
import { LoginComponent } from './vistas/login/login.component';

export const AppRoutes: Routes = [
  
  {path: '', redirectTo:'login', pathMatch:'full' },
  {path: 'login', component: LoginComponent},

  
  
  
  {
    path: '',
    component: FullComponent,
    canActivate:[VigilanteGuard],
    children: [
      {
        path: 'inicio',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  }
];
