import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})



export class VigilanteGuard implements CanActivate {
 
  canActivate(


    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (localStorage.getItem('token') != "" && localStorage.getItem('token' ) != null ){

        return true;



      }else{

        return false;
      }

    
  }


  canActivateRoles(


    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (localStorage.getItem('roles') == "Admin" ){

        return true;



      }else{

        return false;
      }

    
  }
  
}
