import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginI } from 'src/app/modelos/login.interface';
import { ServiceGService } from 'src/app/servicios/service-g.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {


    
  nombre: string = "";
	idUsuario : string = "";
  rol: string ="";
  // rol: string = "";
 ids: string | null = null; // Declaramos la variable ids de tipo string y la inicializamos como null




	DATOSGETFICHA!: LoginI;


  constructor(  private router : Router, private api: ServiceGService) { }

  Logout(){


    localStorage.setItem("token", '');
    localStorage.setItem("idAlumno", '');
    localStorage.setItem("usuario", '');
    localStorage.setItem("roles", '');
    this.router.navigate(['login']);
    
      }

      
  
  ngOnInit(): void {


    this.GetUsuario(this.ids);

  }
      
      GetUsuario(id: string | null ){

        //this.ELEMENT_DATA = this.api.getProductos();
      
        this.ids = localStorage.getItem('usuario');
    
        // id = this.ids;
         
        this.api.GetUsuario(this.ids).subscribe(data => {
        
        //this.NombreGrupo = data;
        
        this.DATOSGETFICHA = data[0];
          
        this.nombre = this.DATOSGETFICHA.nombre;
        this.idUsuario = this.DATOSGETFICHA.idUsuario;
        this.rol = this.DATOSGETFICHA.rol;
        
        
        //console.log(this.DATOSGETFICHA);
        console.log(this.nombre)
        
         
        });
        
        
        }

}
