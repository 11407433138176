import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
import { ServiceGService } from 'src/app/servicios/service-g.service';
import { LoginI } from 'src/app/modelos/login.interface';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  
  nombre: string = "";
	idUsuario : string = "";
  rol: string ="";
  // rol: string = "";
 ids: string | null = null; // Declaramos la variable ids de tipo string y la inicializamos como null




	DATOSGETFICHA!: LoginI;


  constructor(private router : Router,   changeDetectorRef: ChangeDetectorRef,    media: MediaMatcher,    public menuItems: MenuItems , private api: ServiceGService ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  
  ngOnInit(): void {

      this.menuItems.getMenuitem();
    this.GetUsuario(this.ids);

  }

  Logout(){

    
    localStorage.setItem("token", '');
    localStorage.setItem("idAlumno", '');
    localStorage.setItem("usuario", '');
    localStorage.setItem("roles", '');
    this.router.navigate(['login']);
    
    this.menuItems.getMenuitem();



      }
      

      
  GetUsuario(id: string | null ){

    //this.ELEMENT_DATA = this.api.getProductos();



    this.ids = localStorage.getItem('usuario');

    // id = this.ids;
     
    this.api.GetUsuario(this.ids).subscribe(data => {
    
    //this.NombreGrupo = data;
    
    this.DATOSGETFICHA = data[0];
      
    this.nombre = this.DATOSGETFICHA.nombre;
    this.idUsuario = this.DATOSGETFICHA.idUsuario;
    this.rol = this.DATOSGETFICHA.rol;
    
    
    //console.log(this.DATOSGETFICHA);
    //console.log(this.nombre)
    
     
    });
    
    
    }




}
