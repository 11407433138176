        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<a  class=" m-r-20 hidden-sm-up" mat-raised-button color="primary">{{rol}}</a>

<a class="m-r-20 hidden-sm-up" mat-raised-button style="background-color: #96C200;">{{nombre}}</a>


<!-- <h6 class="m-t-0 "> {{rol}} </h6> -->

<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> 

    <span class="material-icons">    logout   </span> 

    
  

</button>

<mat-menu #profile="matMenu" class="mymegamenu">
    
    <button mat-menu-item (click)= "Logout()"  >
        <mat-icon  >exit_to_app</mat-icon> Cerrar Sesion </button>

       
</mat-menu>

